<template>
  <div>
    <el-card>
      <el-form
        ref="form"
        :model="form"
        :rules="rulueFrom"
        label-width="auto"
        :inline="false"
        size="normal"
      >
        <el-form-item label="姓名" prop="realName">
          <el-col :span="10">
            <el-input v-model="form.realName" show-word-limit />
          </el-col>
        </el-form-item>
        <el-form-item label="公司" prop="company">
          <el-col :span="10">
            <el-input v-model="form.company" show-word-limit />
          </el-col>
        </el-form-item>
        <el-form-item label="电话" prop="phone">
          <el-col :span="10">
            <el-input v-model="form.phone" show-word-limit />
          </el-col>
        </el-form-item>
        <el-form-item label="邮箱" prop="email">
          <el-col :span="10">
            <el-input v-model="form.email" show-word-limit />
          </el-col>
        </el-form-item>
        <el-form-item label="企业邮箱" prop="businessEmail">
          <el-col :span="10">
            <el-input v-model="form.businessEmail" show-word-limit />
          </el-col>
        </el-form-item>
        <el-form-item label="微信" prop="weixin">
          <el-col :span="10">
            <el-input v-model="form.weixin" show-word-limit />
          </el-col>
        </el-form-item>
        <el-form-item label="公司地址" prop="companyAddress">
          <el-col :span="10">
            <el-input v-model="form.companyAddress" show-word-limit />
          </el-col>
        </el-form-item>
        <el-form-item label="职位" prop="job">
          <el-col :span="10">
            <el-input v-model="form.job" show-word-limit />
          </el-col>
        </el-form-item>
        <el-form-item label="类型" prop="type">
          <el-col :span="10">
            <el-select
              v-model="form.type"
              multiple
              placeholder="类型"
              size="medium"
            >
              <el-option
                v-for="(item, index) in config.speakerType"
                :key="index"
                :label="item.value"
                :value="item.key + ''"
              />
            </el-select>
          </el-col>
        </el-form-item>
        <el-form-item label="行业" prop="type">
          <el-col :span="10">
            <el-select
              v-model="form.business"
              multiple
              placeholder="行业"
              size="medium"
            >
              <el-option
                v-for="(item, index) in config.speakerBusiness"
                :key="index"
                :label="item.value"
                :value="item.key + ''"
              />
            </el-select>
          </el-col>
        </el-form-item>
        <el-form-item label="职级" prop="jobLevel">
          <el-col :span="10">
            <el-select v-model="form.jobLevel" placeholder="职级" size="medium">
              <el-option
                v-for="(item, index) in config.speakerJobLevel"
                :key="index"
                :label="item.value"
                :value="item.key"
              />
            </el-select>
          </el-col>
        </el-form-item>
        <el-form-item label="城市" prop="city">
          <region :val1.sync="form.province" :val2.sync="form.city"></region>
        </el-form-item>
        <el-form-item label="公司/个人介绍" prop="intro">
          <el-col :span="10" :offset="0">
            <el-input
              v-model="form.desc"
              type="textarea"
              :rows="6"
              placeholder="请输入内容"
            />
          </el-col>
        </el-form-item>
        <el-form-item label="曾参加的活动">
          <fuzzy-selection
            :value.sync="form.activityId"
            ref="fuzzy"
            :type="4"
            multiple
            :tipName="'活动'"
          ></fuzzy-selection>
        </el-form-item>
        <el-form-item label="是否合作过">
          <el-select v-model="form.cooperated" placeholder="是否合作">
            <el-option
              v-for="(item, index) in config.cooperated"
              :key="index"
              :label="item.value"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="是否已认证小程序">
          <el-select v-model="form.cert" placeholder="是否已认证小程序">
            <el-option :label="'未知'" :value="0" />
            <el-option :label="'是'" :value="1" />
            <el-option :label="'否'" :value="2" />
          </el-select>
        </el-form-item> -->
        <el-form-item label="小助手">
          <el-select
            v-model="form.waId"
            placeholder="小助手"
            multiple
            :multiple-limit="3"
          >
            <el-option
              v-for="(item, index) in $store.state.NormalList"
              :key="item.id"
              :label="item.name"
              :value="item.id + ''"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="资料文档">
          <up-file :value.sync="form.document"></up-file>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="medium" @click="onSubmit">{{
            id ? '修改' : '提交'
          }}</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import FuzzySelection from '@/components/fuzzySelection.vue'
import rules from '@/utils/rules.js'
import region from '@/components/global/region.vue'
export default {
  components: {
    region,
    FuzzySelection
  },
  data() {
    return {
      form: {
        realName: '',
        activityId: [],
        waId: [],
        remarkPictures: [],
        type: [],
        business: [],
        cooperated: 0
      },
      rulueFrom: {
        realName: [rules.req('请输入姓名')],
        phone: [rules.req('请输入手机号')]
      },
      config: {}
    }
  },
  created() {
    this.getConfing()
    sessionStorage.setItem('action', 'speaker')

    if (this.id) {
      this.getForm()
    }
  },
  methods: {
    async getConfing() {
      const { data: config } = await this.$http.get(
        '/admin/Common/getConfigDictionaries?config=speaker'
      )
      this.config = config.data
    },
    async getForm() {
      const { data: res } = await this.$http.get(
        '/admin/InsideSpeaker/getById',
        {
          params: {
            id: this.id
          }
        }
      )
      if (res.errorCode == 200) {
        var item = res.data
        this.form = {
          ...res.data,
          waId: item.waId.split(',').filter(Boolean),
          remarkPictures: item.remarkPictures
            .split(',')
            .filter(Boolean)
            .map(i => {
              return { name: i, url: i }
            }),
          activityId: item.activityId.split(',').filter(Boolean),
          type: item.type.split(',').filter(Boolean),
          business: item.business.split(',').filter(Boolean)
        }
        var arr = this.form.activityName.split(',').filter(Boolean)
        this.$refs.fuzzy.options = arr.map((item, i) => {
          return { title: item, id: this.form.activityId[i] }
        })
      }
    },
    async onSubmit() {
      this.$refs.form.validate()
      const form = {
        ...this.form,
        activityId: this.form.activityId.toString(),
        waId: this.form.waId.toString(),
        remarkPictures: this.form.remarkPictures.toString(),
        type: this.form.type.toString(),
        business: this.form.business.toString()
      }

      if (this.id) {
        var url = '/admin/InsideSpeaker/edit'
      } else {
        var url = '/admin/InsideSpeaker/add'
      }
      const { data: res } = await this.$http.post(url, form)
      if (res.errorCode == 200) {
        this.$message.success(res.message)
        this.$router.go(-1)
      }
    }
  },

  computed: {
    id() {
      return this.$route.query.id
    }
  }
}
</script>

<style lang="less" scoped></style>
